import { SnackbarProvider } from "notistack";
import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { store } from "redux/store";
import Test from "pages/test/Test";
import { memo } from "react";
const LayoutChange = React.lazy(() => import("pages/LayoutChange"));
const Auth = React.lazy(() => import("pages/auth/Auth"));
const About = React.lazy(() => import("pages/About"));
const Dashboard = React.lazy(() => import("pages/protected/Dashboard"));
const Loader = React.lazy(() => import("components/loader/Loader"));
const NotFound = React.lazy(() => import("pages/NotFound"));
const AuthLayout = React.lazy(() => import("layouts/AuthLayout"));
const ProtectedLayout = React.lazy(() => import("layouts/ProtectedLayout"));
const SuccessResetPassword = React.lazy(() => import("components/Authentication/SuccessResetPassword"));
const LockScreen = React.lazy(() => import("components/lockScreen/LockScreen"));

const theme = createTheme({
  typography: {
    fontSize: 12,
  },
});

const IS_ELECTRON_ROUTE = false;

function App() {
  return (
    <div className="App">
      <React.Suspense fallback={<Loader />}>
        <Provider store={store}>
          {IS_ELECTRON_ROUTE ? (
            <HashRouter>
              {/* Your routing setup for Electron */}
              <SnackbarProvider
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                maxSnack={1}
                autoHideDuration={3000}
              >
                <ThemeProvider theme={theme}>
                  <Routes>
                    <Route element={<AuthLayout />}>
                      {/* Auth route start from here. */}
                      <Route path="/" element={<Auth />} />
                      {/* Auth route end here. */}
                    </Route>
                    <Route element={<ProtectedLayout />}>
                      {/* Protected route start from here. */}
                      <Route path="/dashboard" element={<Dashboard />} />
                      {/* Protected route end here. */}
                    </Route>
                    {/* Other route start here. */}
                    <Route path="/about" element={<About />} />
                    <Route path="/layout-change" element={<LayoutChange />} />
                    {/* ... (other components) */}
                    {/* Other route end here. */}
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </ThemeProvider>
              </SnackbarProvider>
            </HashRouter>
          ) : (
            <BrowserRouter>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                maxSnack={1}
                autoHideDuration={3000}
              >
                <ThemeProvider theme={theme}>
                  <Routes>
                    <Route element={<AuthLayout />}>
                      {/* Auth route start from here. */}
                      <Route path="/" element={<Auth />} />
                      {/* Auth route end here. */}
                    </Route>
                    <Route element={<ProtectedLayout />}>
                      {/* Protected route start from here. */}
                      <Route path="/dashboard" element={<Dashboard />} />
                      {/* Protected route end here. */}
                    </Route>
                    {/* Other route start here. */}
                    <Route path="/about" element={<About />} />
                    <Route path="/layout-change" element={<LayoutChange />} />
                    {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
                    <Route path="/success-reset-password" element={<SuccessResetPassword />} />
                    <Route path="/test" element={<Test />} />
                    <Route path="/lock-screen" element={<LockScreen />} />
                    {/* Other route end here. */}
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </ThemeProvider>
              </SnackbarProvider>
            </BrowserRouter>
          )}
        </Provider>
      </React.Suspense>
    </div>
  );
}

export default memo(App);
