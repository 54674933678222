import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initialTime: 90,
  timeLeft: 90,
};

const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    ticker: (state) => {
      if (state.timeLeft === 0) {
        state.timeLeft = state.initialTime;
      } else {
        state.timeLeft--;
      }
    },
    setInitialTime: (state, action) => {
      state.initialTime = action.payload;
      state.timeLeft = action.payload;
    },
  },
});

export const { ticker, setInitialTime } = timerSlice.actions;

export default timerSlice.reducer;