import { createSlice } from "@reduxjs/toolkit";

const priceSlice = createSlice({
  name: 'price',
  initialState: {
    currentPrice: {},
  },
  reducers: {
    setCurrentPrice: (state, action) => {
      state.currentPrice[action.payload.currency] = action.payload.price;
    },
  },
});

export const {setCurrentPrice} = priceSlice.actions;

export default priceSlice.reducer;
