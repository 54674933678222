import { createSlice } from '@reduxjs/toolkit';

const chartSlice = createSlice({
    name: 'chart',
    initialState: {
        chart: null,
        socket: null,
    },
    reducers: {
        setChart: (state, action) => {
            state.chart = action.payload;
        },
        setSocketInstance: (state, action) => {
            state.socket = action.payload;
        },
    },
});
export const { setChart, setSocketInstance } = chartSlice.actions;
export default chartSlice.reducer;