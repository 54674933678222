import { createSlice } from "@reduxjs/toolkit";

const dealsConfirmationSlice = createSlice({
    name: "confirmation",
    initialState: {
        dealsConfirmDialog: JSON.parse(localStorage.getItem("dealsConfirmDialog")) || false,
    },
    reducers: {
        setDealsConfirmDialogs: (state, action) => {
            state.dealsConfirmDialog = action.payload;
        },
    },
});

export const { setDealsConfirmDialogs } = dealsConfirmationSlice.actions;

export default dealsConfirmationSlice.reducer;