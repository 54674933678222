import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../redux/features/counter/counterSlice";
import applicationReducer from "../redux/features/application/applicationSlice";
import authReducer from "../redux/features/auth/authSlice";
import timerReducer from "../redux/features/timer/timerSlice";
import tradesSlice from "../redux/features/trades/tradesSlice";
import dealsSlice from "./features/deals/dealsSlice";
import currenciesSlice from "./features/currencies/currenciesSlice";
import priceSlice from "./features/price/priceSlice";
import chartSlice from "./features/chart/chartSlice";
import dealsConfirmationSlice from "./features/deals/dealsConfirmationSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    application: applicationReducer,
    auth: authReducer,
    timer: timerReducer,
    trades: tradesSlice,
    deals: dealsSlice,
    currency: currenciesSlice,
    price: priceSlice,
    chart: chartSlice,
    confirmation: dealsConfirmationSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});
