import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openTrades: [],
  balance: 0.0,
  tradeResults: [],
  history: [],
};

export const tradesSlice = createSlice({
  name: "trades",
  initialState,
  reducers: {
    setOpenTrades: (state, action) => {
      state.openTrades = action.payload;
    },
    setOpenTrade: (state, action) => {
      state.openTrades = [...state.openTrades, action.payload];
    },
    removeTrade: (state, action) => {
      state.openTrades = state.openTrades.filter((item) => item.id !== action.payload);
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    addBalance: (state, action) => {
      state.balance = state.balance + action.payload;
    },
    deductBalance: (state, action) => {
      state.balance = state.balance - action.payload;
    },
    setGetBalancePump: (state, action) => {
      if (action?.payload?.type === "WITHDRAW") {
        state.balance -= action?.payload?.amount
      }else{
        state.balance += action?.payload?.amount
      }
    },
    setTradeHistory: (state, action) => {
      state.history = action.payload;
    },
    addTradeResult: (state, action) => {
      state.tradeResults = [...state.tradeResults, action.payload];
    },
    removeTradeResult: (state) => {
      state.tradeResults = [];
    },
    addTradeHistory: (state, action) => {
      state.history = [...state.history, action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOpenTrade, setOpenTrades, setBalance, setGetBalancePump, addBalance, deductBalance, removeTrade, setTradeHistory, addTradeResult, removeTradeResult, addTradeHistory } = tradesSlice.actions;

export default tradesSlice.reducer;
