import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTab: 0,
  selectedRightTab: 0,
  selectedIndicatorTab: 0,
  selectedChartTypeTab: 0,
  selectedLayoutChange: 1,
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    changeTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    changeRightTab: (state, action) => {
      state.selectedRightTab = action.payload;
    },
    changeIndicatorTab: (state, action) => {
      state.selectedIndicatorTab = action.payload;
    },
    changeChartTypeTab: (state, action) => {
      state.selectedChartTypeTab = action.payload;
    },
    changeSelectedLayout: (state, action) => {
      state.selectedLayoutChange = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeTab, changeRightTab, changeIndicatorTab, changeChartTypeTab, changeSelectedLayout } = applicationSlice.actions;

export default applicationSlice.reducer;
