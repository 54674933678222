import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dealsResult: {},
};

export const dealsSlice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    setDealsOpen: (state, action) => {
        state.dealsResult = action.payload;
      },
  },
});

// Action creators are generated for each case reducer function
export const { setDealsOpen } = dealsSlice.actions;

export default dealsSlice.reducer;
