import { Howl } from 'howler';
import React from 'react'

function Test() {
    const handlePlay = () => {
        const sound = new Howl({
          src: ['https://app.cryptocx-server.com/assets/sound/close_trade.wav'],
        });
        sound.play();
      };
  return (
    <div>
        <div>
      <button onClick={handlePlay}>Play Audio</button>
    </div>
    </div>
  )
}

export default Test
