import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currencyList: [],
};

const currenciesSlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    getCurrencyList: (state, action) => {
      state.currencyList = action.payload;
    },
  },
});

export const { getCurrencyList } = currenciesSlice.actions;

export default currenciesSlice.reducer;
