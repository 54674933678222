import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslate from "./locales/en.json";
import hiTranslate from "./locales/hi.json";
import arTranslate from "./locales/ar.json";
import trTranslate from "./locales/tr.json";
import idTranslate from "./locales/id.json";
import msTranslate from "./locales/ms.json";

const resource = {
  resources: {
    en: {
      translation: enTranslate,
    },
    hi: {
      translation: hiTranslate,
    },
    ar: {
      translation: arTranslate,
    },
    tr: {
      translation: trTranslate,
    },
    id: {
      translation: idTranslate,
    },
    ms: {
      translation: msTranslate,
    },
  },
  lng: localStorage.getItem("lang") ?? "en",
  fallbackLng: localStorage.getItem("lang") ?? "en",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(resource);

export default i18n;
